import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  AppState,
  CollapseSidebar,
  DiscoveryChannelState,
  FilterTxnOmniChannel,
  ModeViewPost,
  NameAppStore,
  PER_PAGE_DISCOVERY_CHANNEL,
  PopupHover,
  SidebarTabs,
  SplitMode,
  ViewTxn,
  initStateMyWorksV2
} from './app-state.model';

export function createInitialState(): AppState {
  return <AppState>{
    sidebarTabActive: SidebarTabs.teamchat,
    emailUWState: {
      isExpandPersonal: true,
      isExpandTeam: false,
      isExpandTeammate: false
    },
    mentionCountTeamChat: 0,
    quillEditor: {
      triggerfocus: false
    },
    popupState: {
      isShowPopup: false,
      isLoadingTemplate: false,
      tag: {}
    },
    triggerRefreshTxn: false,
    customerTxnReports: [],
    collapseSidebar: <CollapseSidebar>{
      inboxes: true,
      me: false,
      starred: false,
      watching: true,
      related: true,
      created: true,
      channels: false
    },
    splitMode: SplitMode.horizontalSplit,
    isFetchingTxnDetail: false,
    isOpenLeftSidebar: true,
    isOpenInternalSpace: true,
    isOpenSearch: true,
    discoveryChannelState: <DiscoveryChannelState>{
      loaded: false,
      page: 1,
      perPage: PER_PAGE_DISCOVERY_CHANNEL,
      hasMore: false
    },
    discoveryNewsfeedState: <DiscoveryChannelState>{
      loaded: false,
      page: 1,
      perPage: PER_PAGE_DISCOVERY_CHANNEL,
      hasMore: false
    },
    recommandNewsfeedState: <DiscoveryChannelState>{
      loaded: false,
      page: 1,
      perPage: PER_PAGE_DISCOVERY_CHANNEL,
      hasMore: false
    },
    hasRobinChat: false,

    // omni
    channelsByLicense: [],
    latestViewMyWorks: ViewTxn.assigned,
    myWorkV2: initStateMyWorksV2(),
    pendingTxns: [],
    isFullScreen: false,
    modeViewPost: ModeViewPost.preview,
    omniChannelFilter: <FilterTxnOmniChannel>{
      filter: {
        statuses: [],
        inboxUuids: [],
        reporters: []
      },
      textSearch: '',
      txnUuidsFilter: [],
      state: {
        loaded: false,
        page: 1,
        perPage: 50,
        hasMore: false
      }
    },
    forceWidgetPage: false,
    popupHover: <PopupHover>{
      uuid: null,
      visible: false,
      position: undefined,
      isHoveringPopup: false
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: NameAppStore })
export class AppStore extends Store<AppState> {
  constructor() {
    super(createInitialState());
  }
}

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, Route, RouterModule } from '@angular/router';
import { TxnNotificationComponent } from '@b3networks/chat/shared/txn-notification';
import { PortalAuthInterceptor } from '@b3networks/portal/base/shared';
import { INJECT_PORTAL_DOMAIN, SentryErrorHandler, SharedCommonModule } from '@b3networks/shared/common';
import { SharedUiLoadingSpinnerModule } from '@b3networks/shared/ui/loading-spinner';
import {
  ButtonLoadingModule,
  SharedUiMaterialModule,
  SharedUiMaterialNativeDateModule
} from '@b3networks/shared/ui/material';
import { DARK_MODE, LIGHT_MODE } from '@b3networks/shared/ui/theme';
import { SharedUiToastModule } from '@b3networks/shared/ui/toast';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AppComponent } from './app.component';
import { LandingPageModule } from './landing-page/landing-page.module';
import { MainViewComponent } from './main-view/main-view.component';
import { NavigateOrgComponent } from './navigate-org/navigate-org.component';
import { SecurityPolicyComponent } from './security/security.component';
import { SharedModule } from './shared/shared.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserProfileMenuComponent } from './sidebar/user-profile-menu/user-profile-menu.component';
import { THEME_DARK_MODE } from './theme/darkmode-variable';
import { THEME_LIGHT_MODE } from './theme/lightmode-variable';
import { TransformTitleAppNamePipe } from './shared/pipe/transform-title-app-name.pipe';

const routes: Route[] = [
  // {
  //   path: ':orgUuid/home',
  //   loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
  //   data: { title: 'Home' }
  // },
  {
    path: ':orgUuid/account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    data: { title: 'Manage account' }
  },
  {
    path: ':orgUuid/org-chart',
    loadChildren: () => import('./org-chart-page/org-chart-page.module').then(m => m.OrgChartPageModule),
    data: { title: 'Organization chart' }
  },
  { path: 'security-check', component: SecurityPolicyComponent, data: { showMainSidebar: false, title: 'Security' } },
  {
    path: 'auth',
    loadChildren: () => import('@b3networks/portal/base/feature/auth').then(m => m.PortalBaseFeatureAuthModule),
    data: { showMainSidebar: false, title: 'Authentication' }
  },
  {
    path: ':orgUuid/renewSubscription/:recoverUuid',
    loadChildren: () =>
      import('./recover-subscription/recover-subscription.module').then(m => m.RecoverSubscriptionModule),
    data: { title: 'Recover subscription' }
  }, // for recover subscription only.
  {
    path: ':orgUuid/:view',
    component: MainViewComponent,
    children: [
      {
        path: '**',
        component: MainViewComponent
      }
    ]
  },
  { path: 'access-denied', component: AccessDeniedComponent, data: { showMainSidebar: false } },
  { path: 'error', loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule) },
  { path: '**', component: NavigateOrgComponent, data: { showMainSidebar: false } }
];

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    SecurityPolicyComponent,
    MainViewComponent,
    NavigateOrgComponent,
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      useHash: true,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules
    }),

    environment.production ? [] : AkitaNgDevtools.forRoot(),

    SharedUiToastModule.forRoot(),
    SharedUiLoadingSpinnerModule,
    SharedCommonModule,
    SharedModule,
    SharedUiMaterialModule,
    ButtonLoadingModule,

    LandingPageModule,
    SharedUiMaterialNativeDateModule,
    TxnNotificationComponent,
    UserProfileMenuComponent,
    TransformTitleAppNamePipe
  ],
  providers: [
    {
      provide: LIGHT_MODE,
      useValue: THEME_LIGHT_MODE
    },
    {
      provide: DARK_MODE,
      useValue: THEME_DARK_MODE
    },
    { provide: HTTP_INTERCEPTORS, useClass: PortalAuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: INJECT_PORTAL_DOMAIN, useValue: environment.portalDomain }
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { StateSearch, ViewChannelSection, ViewUIStateCommon } from '../../enums.model';
import { ReqSearchFromNamespaces, RequestSearchCriteria } from './search-criteria.model';

export interface ChannelUI extends ViewUIStateCommon {
  id: string; // id entity

  withMe?: ChannelUIWithMe;

  // state file strorage
  file?: {
    loaded?: boolean; // load first
    page?: number; // 1
    perPage?: number; // 10,
    hasMore: boolean;
  };
}

export interface ChannelUIWithMe {
  emc: number; // estimated message count
}

export function createInitUI() {
  return <ChannelUI>{
    loaded: false,
    loadedFirst: false,
    viewingOlderMessage: false,
    file: {
      loaded: false,
      page: 1,
      perPage: 10,
      hasMore: true
    },
    previewHistory: {
      loadedFirst: false,
      hasMoreTop: false,
      hasMoreBottom: false
    },
    rangeThreadsParent: {
      loadedFirst: false,
      hasMoreBackward: false,
      hasMoreForward: false
    },
    stateSearch: <StateSearch<RequestSearchCriteria>>{
      isOpen: false,
      filter: {}
    },
    stateSearchFeed: <StateSearch<ReqSearchFromNamespaces>>{
      isOpen: false,
      filter: {}
    },
    viewChannelSection: ViewChannelSection.chat
  };
}

<ng-container *ngIf="!confirming; else confirm">
  <h1 mat-dialog-title>Recovery key</h1>

  <div mat-dialog-content>
    <div class="flex flex-col gap-4">
      <p>The recovery key allows account access if two-factor authentication code cannot be generated</p>
      <h1 class="text-center">{{ recoveryKey }}</h1>
      <div class="flex gap-1 bg-warning">
        <mat-icon>info</mat-icon>
        <div>
          <p>Treat your recovery key with the same level of attention as your password.</p>
          <p>Remember to download and securely store it.</p>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-stroked-button (click)="openConfirmDialog()">Close</button>
    <button mat-flat-button color="primary" (click)="download()">Download</button>
  </div>
</ng-container>

<ng-template #confirm>
  <h1 mat-dialog-title>Enable two-factor authentication</h1>

  <div mat-dialog-content>
    You have not downloaded your recovery key yet. Make sure you have kept your Recovery Key in a safe place.
  </div>

  <div mat-dialog-actions align="end">
    <button mat-stroked-button (click)="back()">Back</button>
    <button mat-flat-button color="primary" (click)="finish()">Finish</button>
  </div>
</ng-template>

import { ChatMessage } from '../../chat-message/chat-message.model';
import { UserType } from '../../enums.model';
import { ChannelType } from './enum-channel.model';

export interface MessageSearchResult {
  count: number;
  total: number;
  messages: ChatMessage[];
  fromMillis: number;
  toMillis: number;
}

export class RequestSearchCriteria {
  convoIDs: string[];
  userIDs: string[];
  message: string;
  fromMillis: number;
  toMillis: number;
  limit: number;
  asc: boolean; // default decs
}

export interface ReqSearchFromNamespaces {
  convos: SearchRequestConvo[];
  users: SearchRequestUser[];
  userTypes: UserType[];
  messageTypesRaw: string[];

  from: string;
  to: string;
  fromMillis?: number;
  toMillis?: number;
  fromInclusive: boolean;
  toInclusive: boolean;
  limit: number;
  asc: boolean;
  query: string;
}

export class ResSearchFromNamespaces {
  messages: ChatMessage[];
  count: number;
  from?: string | undefined;
  to?: string | undefined;

  // ui
  fromMillis?: number;
  toMillis?: number;

  constructor(obj?: Partial<ResSearchFromNamespaces>) {
    if (obj) {
      Object.assign(this, obj);
      this.messages = this.messages?.map(msg => new ChatMessage(msg)) || [];
      if (this.messages?.length > 0) {
        this.fromMillis = Math.min(this.messages?.[0].ts, this.messages?.[this.messages.length - 1].ts);
        this.toMillis = Math.max(this.messages?.[0].ts, this.messages?.[this.messages.length - 1].ts);
      }
    }
  }
}

export interface SearchRequestConvo {
  id: string;
  namespace: string;
  type: ChannelType;
}

export interface SearchRequestUser {
  id: string;
  namespace: string;
}

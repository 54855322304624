<div mat-dialog-title class="store-announcement__title mt-24">
  <ng-container *ngIf="data.announcement && !isEditMode; else storeTitle">
    <div class="info">
      <ng-container *ngIf="user">
        <img *ngIf="user.photoUrl" [src]="user.photoUrl" />
        <span>{{ user.displayName }}</span>
        <span>•</span>
      </ng-container>
      <span>{{ data.announcement.createdAt | date: 'HH:mm, dd MMM yyyy' }}</span>
    </div>
  </ng-container>
  <ng-template #storeTitle>
    <h1>{{ isEditMode ? 'Edit' : 'Create' }} Announcement</h1>
  </ng-template>
</div>
<div mat-dialog-content class="store-announcement__content" [formGroup]="form">
  <ng-container *ngIf="data.announcement && !isEditMode; else storeContent">
    <div class="body">
      <h1>{{ titleFC.value }}</h1>
      <p class="content">{{ contentFC?.value }}</p>
    </div>
  </ng-container>
  <ng-template #storeContent>
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input matInput required [formControl]="titleFC" [maxlength]="TITLE_MAX_LENGTH" />
      <mat-error>Title is required</mat-error>
      <mat-hint> {{ titleFC.value.length }}/{{ TITLE_MAX_LENGTH }} </mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Content</mat-label>
      <textarea matInput required [formControl]="contentFC" [maxlength]="CONTENT_MAX_LENGTH"></textarea>
      <mat-error>Content is required</mat-error>
      <mat-hint> {{ contentFC.value.length }}/{{ CONTENT_MAX_LENGTH }} </mat-hint>
    </mat-form-field>
  </ng-template>
</div>
<div mat-dialog-actions align="end" class="store-announcement__actions">
  <ng-container *ngIf="data.announcement && !isEditMode; else storeActions">
    <button mat-stroked-button color="warn" (click)="confirmArchive()">Archive</button>
    <button mat-stroked-button color="primary" (click)="isEditMode = true">Edit</button>
    <button mat-stroked-button mat-dialog-close>Close</button>
  </ng-container>
  <ng-template #storeActions>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" [disabled]="form.invalid" [loading]="saving" (click)="storeAnnouncement()">
      {{ isEditMode ? 'Save' : 'Create' }}
    </button>
  </ng-template>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReqSendMsgInbox } from '@b3networks/api/inbox';
import {
  DomainUtilsService,
  IdleService,
  isLocalhost,
  MethodName,
  ReconnectChatStragery,
  SendMessageEventData,
  X
} from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';
import { PreviewMessageData } from '../chat-message/chat-message-data.model';
import { ExtraData, LinkedMessages } from '../chat-message/chat-message-extra-data.model';
import { ChatMessage } from '../chat-message/chat-message.model';
import { ConvoType, SystemType } from '../enums.model';
import { ChatSession, ChatTopic, SocketStatus } from './chat-session.model';

export interface ChatState {
  orgUuid: string;
  appId: string;
  session: ChatSession;
  socket: WebSocket;
  socketStatus: SocketStatus;
  reconnectStragery: ReconnectChatStragery;

  // state
  isPublic: boolean;
  isV2: boolean;
}

export interface ResquestTokenCustomer {
  orgUuid: string;
  displayName?: string;
  name?: string;
  email: string;
}

@Injectable({ providedIn: 'root' })
export class ChatService {
  tabUuid: string;

  useWebSoketPortal: boolean; // when true, websocket init on portal-base
  useNotificationWebSoketPortal: boolean; // when true, handle notification on portal-base not iframe

  private _state: ChatState;
  private _message$: Subject<ChatMessage> = new Subject();
  private _socketStatus$: BehaviorSubject<SocketStatus> = new BehaviorSubject<SocketStatus>(SocketStatus.none);
  private _session$: BehaviorSubject<ChatSession> = new BehaviorSubject<ChatSession | null>(null);
  private _timeLatestMsg: number;
  private _wsUrlCurrent: string;

  private _listSocketInited: WebSocket[] = [];

  constructor(
    private http: HttpClient,
    private domainService: DomainUtilsService,
    private idleService: IdleService,
    private toastService: ToastService
  ) {
    this._listSocketInited = [];
    this._state = <ChatState>{
      reconnectStragery: new ReconnectChatStragery()
    };
  }

  get state() {
    return this._state;
  }

  get currentOrgUuid() {
    return this._state ? this._state.orgUuid : null;
  }

  get session$() {
    return this._session$.asObservable();
  }

  get session() {
    return this._state ? this._state.session : null;
  }

  get socketStatus$() {
    return this._socketStatus$.asObservable();
  }

  emitMessage(message: ChatMessage) {
    this._message$.next(message);
  }

  getSubscribeTopic() {
    return this.http.post<{ topics: ChatTopic[] }>(`/public/v2/user/wss/getSubscription`, {});
  }

  subscribeTopic(nameTopics: ChatTopic[]) {
    return this.http.post(`/public/v2/user/wss/subscribe`, {
      topics: nameTopics
    });
  }

  unsubscribeTopic(nameTopics: ChatTopic[]) {
    return this.http.post(`/public/v2/user/wss/unsubscribe`, {
      topics: nameTopics
    });
  }

  initChat(req: { orgUuid: string; appId?: string; bypass?: boolean; isV2?: boolean; reason?: string }) {
    if (req.bypass || this._state.orgUuid !== req.orgUuid) {
      this.normalClose();
      this.updateSocketStatus(SocketStatus.connecting);
      this._state.orgUuid = req.orgUuid;
      this._state.appId = req.appId;
      this._state.isV2 = req.isV2;

      return this.initChatSession().pipe(
        switchMap(session => {
          return this.init(session, req.reason);
        })
      );
    }
    return of(null);
  }

  initPublicChatV2(session: ChatSession, orgUuid: string) {
    this._state.isPublic = true;
    this._state.orgUuid = orgUuid;
    return this.init(session);
  }

  refreshTokenPublicChatV2(orgUuid: string) {
    this._state.isPublic = true;
    this._state.orgUuid = orgUuid;
    return this.refreshChatSession(orgUuid).pipe(
      switchMap(session => {
        return this.init(session);
      })
    );
  }

  send(message: string | ChatMessage): boolean {
    // console.log(`send message via societ: ${JSON.stringify(data)}`);
    let result: boolean;
    try {
      if (this.useWebSoketPortal) {
        let msgToPortal: string;
        if (message instanceof ChatMessage) {
          msgToPortal = message.toJSONString();
        } else {
          msgToPortal = <string>message;
        }

        X.fireMessageToParent(MethodName.SendMessage, <SendMessageEventData>{
          message: msgToPortal // dont sanitize msg because ws Portal-base will sanitize it
        });
        result = true;
      } else {
        if (this.isOpening()) {
          let msgSanitized: string;
          let isPreview = false;
          if (message instanceof ChatMessage) {
            isPreview = this._checkIsPreview(message);
            msgSanitized = this.sanitizeMsg(message);
          } else {
            const converter = this.convertStringToJson(message);
            isPreview = this._checkIsPreview(converter);
            msgSanitized = this.sanitizeMsg(converter); // receive msg from iframe // TODO: increase performance-> emit msg before send msg
          }

          const blob = new Blob([msgSanitized]);
          if (blob.size > 4 * 1024) {
            if (!isPreview) this.toastService.error('message-too-large');
          } else {
            this._state.socket.send(msgSanitized);

            // handle UI first before receive from BE
            let msgRoot: string;
            if (message instanceof ChatMessage) {
              msgRoot = message.toJSONString();
            } else {
              msgRoot = <string>message;
            }
            this.emitMessage(this.convertStringToJson(msgRoot));
          }
          result = true;
        } else {
          console.error({ code: 'noChatSessionOpening', message: 'No chat session opening' });
          // throw { code: 'noChatSessionOpening', message: 'No chat session opening' };
        }
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  }

  onmessage(): Observable<ChatMessage> {
    return this._message$.asObservable().pipe(share());
  }

  clearWs() {
    if (this._state.socket) {
      this._state.socket.close();
      this._state.socket = null;
    }

    this._listSocketInited?.forEach(socket => {
      socket.close(1000);
    });
  }

  normalClose() {
    if (this._state.socket) {
      this._state.socket.close(1000); //the connection successfully completed whatever purpose for which it was created.
    }
  }

  abnormalClose() {
    if (this._state.socket) {
      this._state.socket.close(3000); //the connection successfully completed whatever purpose for which it was created.
    }
  }

  updateSocketStatus(status: SocketStatus) {
    this._state.socketStatus = status;
    this._socketStatus$.next(status);
  }

  updateSessionChat(session: ChatSession) {
    this._state.session = session;
    this._session$.next(session);
  }

  reconnect(req: { forceReset?: boolean; reason: string } = { forceReset: false, reason: '' }) {
    console.log(`======> LOG: ${req.reason} ${req.forceReset ? ', has forceReset' : ''} `);
    if (this._state.socketStatus === SocketStatus.connecting) {
      console.log('already reconnect, waiting for result...');
      return;
    }

    if (req.forceReset) {
      this._state.reconnectStragery.reset();
    }
    console.log(`reconnecting...: ${this._state.reconnectStragery.reconnectTimes}/5`);
    if (this._state.reconnectStragery.canReconnect) {
      this.updateSocketStatus(SocketStatus.connecting);

      setTimeout(
        () => {
          if (this._state.isPublic) {
            this.refreshChatSession(this._state.orgUuid).subscribe(
              session => {
                this.normalClose();
                this.init(session, req?.reason).subscribe();
              },
              _ => {
                this.updateSocketStatus(SocketStatus.closed);
                this.reconnect({ reason: 'call api init public ws fail' });
              }
            );
          } else {
            this.initChatSession().subscribe(
              session => {
                this.normalClose();
                this.init(session, req?.reason).subscribe();
              },
              err => {
                this.updateSocketStatus(SocketStatus.closed);
                this.reconnect({ reason: 'call api init ws fail' });
              }
            );
          }
        },
        req.forceReset ? 0 : this._state.reconnectStragery.waitingTime
      );
      this._state.reconnectStragery.increaseReconnectTime();
    } else {
      console.log('reached reconnect times...');
    }
  }

  sendLiveChatMessage(convoId: string, req: ReqSendMsgInbox) {
    return this.http.post(`inbox/public/v2/livechat/${convoId}/_postMessage`, req);
  }

  private init(session: ChatSession, reason?: string): Observable<any> {
    if (this._state.socket && this._state.socket.readyState === 0) {
      return null;
    }

    let wsAddress = session.addr;
    if (!isLocalhost() && !this._state.isPublic) {
      wsAddress = `${this.domainService.getPortalDomain()}/_${session.chatNode}`;
    }

    const wsUrl = `wss://${wsAddress}/public/user/${session.chatUser}/wss/${session.token}?ns=${session.ns}${
      this.state.isV2 ? '&version=2' : ''
    }${this.tabUuid ? '&tabUuid=' + this.tabUuid : ''}${reason ? '&reason=' + reason : ''}`;
    const socket = new WebSocket(wsUrl);
    this._listSocketInited.push(socket);

    return new Observable(ob => {
      let runFirstOpen = false;
      this._wsUrlCurrent = socket?.url;

      socket.onmessage = evt => {
        if (evt?.target?.['url'] !== this._wsUrlCurrent) {
          return;
        }

        if (evt.data) {
          let data = this.convertStringToJson(evt.data);
          if (!!data?.ts && !data.st) {
            if (!this._timeLatestMsg) {
              this._timeLatestMsg = data.ts;
            } else {
              if (data.ts <= this._timeLatestMsg) {
                const time = this._timeLatestMsg + 1; // add 1 ms
                data = new ChatMessage({ ...data, ts: time });
              }
              this._timeLatestMsg = data.ts;
            }
          }

          this.emitMessage(data);
        }
      };

      socket.onopen = evt => {
        if (runFirstOpen) {
          return;
        }
        runFirstOpen = true;

        console.log('onopen', evt?.target?.['url'] === this._wsUrlCurrent);
        // old socket
        if (evt?.target?.['url'] !== this._wsUrlCurrent) {
          return;
        }

        this._state.socket = socket;
        this.cleanUpOldWs();
        this.updateSessionChat(session);

        if (!this._message$ || this._message$.isStopped) {
          this._message$ = new Subject();
        }

        this._state.reconnectStragery.reset();
        this.updateSocketStatus(SocketStatus.opened);
        this.idleService.start();

        ob.next({ status: 'success' });
        ob.complete();
      };

      let runFirstClose = false;
      socket.onclose = evt => {
        if (runFirstClose) {
          return;
        }
        runFirstClose = true;

        // old socket
        if (evt?.target?.['url'] !== this._wsUrlCurrent) {
          return;
        }
        console.log('onclose', evt);
        if (evt?.code === 1006) {
          // manual connect ws
          this.state.reconnectStragery.reconnectTimes = this.state.reconnectStragery.maxRetry;
        }

        this.updateSocketStatus(SocketStatus.closed);
        if (![1000, 1006].includes(evt?.code)) {
          // with 1006 status should check on error
          this.reconnect({ reason: 'evt.code !== 1000' });
        }
        this.idleService.stop();

        ob.next({ status: 'closed' });
        ob.complete();
      };

      let runFirstError = false;
      socket.onerror = evt => {
        if (runFirstError) {
          return;
        }
        runFirstError = true;

        // old socket
        if (evt?.target?.['url'] !== this._wsUrlCurrent) {
          return;
        }
        console.log('onerror', evt);
        this.updateSocketStatus(SocketStatus.closed);

        this.idleService.stop();
        this.reconnect({ reason: 'by socket.onError' });

        ob.error();
        ob.complete();
      };
    });
  }

  private isOpening() {
    return this._state.socket && this._state.socket.readyState === WebSocket.OPEN;
  }

  private initChatSession() {
    return this.http
      .post<ChatSession>(`inbox/private/v1/chat/_initSession`, { ts: new Date().valueOf() })
      .pipe(map(session => new ChatSession(session)));
  }

  private refreshChatSession(orgUuid: string) {
    return this.http
      .post<{ id: string; addr: string; token: string }>(`inbox/public/v2/livechat/_refreshChatSession`, {
        orgUuid
      })
      .pipe(map(session => new ChatSession({ ...session, chatUser: session.id, ns: orgUuid })));
  }

  public sanitizeMsg(msg: ChatMessage) {
    if (!msg) return null;

    const cloned = <ChatMessage>{};
    if (msg.id) cloned.id = msg.id;
    if (msg.ts) cloned.ts = msg.ts;
    if (msg.client_ts) cloned.client_ts = msg.client_ts;
    if (msg.convo) cloned.convo = msg.convo;
    if (msg.hs) cloned.hs = msg.hs;
    if (msg.ns) cloned.ns = msg.ns;
    if (msg.user) cloned.user = msg.user;
    if (msg.ut) cloned.ut = msg.ut;
    if (msg.ct) cloned.ct = msg.ct;
    if (msg.mt) cloned.mt = msg.mt;
    if (msg.st) cloned.st = msg.st;
    if (msg.body) cloned.body = msg.body;
    if (msg.tf) cloned.tf = msg.tf;
    if (msg.topics) cloned.topics = msg.topics;
    if (msg.pf) cloned.pf = msg.pf;
    if (msg.extraData) cloned.extraData = this.sanitizeMsgExtraData(msg.extraData);
    if (cloned?.body?.data !== null && typeof cloned?.body?.data === 'object') {
      cloned.body.data = JSON.stringify(cloned.body.data);
    }
    return JSON.stringify(cloned);
  }

  private sanitizeMsgExtraData(extra: ExtraData) {
    const cloned = <ExtraData>{};
    if (extra.linkedMessages) {
      const link = <LinkedMessages>{};
      if (extra.linkedMessages?.replyTo) link.replyTo = extra.linkedMessages?.replyTo;
      if (extra.linkedMessages?.srcId) link.srcId = extra.linkedMessages?.srcId;
      cloned.linkedMessages = link;
    }
    return cloned;
  }

  private _checkIsPreview(m: ChatMessage) {
    const data = <PreviewMessageData>m?.body?.data;
    if (data) {
      const isTeamchat = [ConvoType.direct, ConvoType.groupchat, ConvoType.THREAD].includes(m.ct);
      return isTeamchat && m.st === SystemType.EDIT && Boolean(data?.desc || data?.icon || data?.image || data?.title);
    }
    return false;
  }

  private convertStringToJson(message: string): ChatMessage | null {
    try {
      return new ChatMessage(JSON.parse(message));
    } catch (error) {
      return null;
    }
  }

  private cleanUpOldWs() {
    setTimeout(() => {
      this._listSocketInited?.forEach(socket => {
        if (this._wsUrlCurrent !== socket.url) {
          socket.close(1000);
        }
      });
    }, 1000);
  }
}

import { Injectable } from '@angular/core';
import { HashMap, Query } from '@datorama/akita';
import { AppNameActionPermission, AppNamePermission } from './self-permission.model';
import { SelfPermissionStore } from './self-permission.store';

@Injectable({ providedIn: 'root' })
export class SelfPermissionQuery extends Query<HashMap<string>> {
  constructor(protected override store: SelfPermissionStore) {
    super(store);
  }

  selectPermissions(groupName: string) {
    return this.select(groupName);
  }

  selectPermissionsByAppName(appName: AppNamePermission) {
    return this.getValue()?.[appName];
  }

  selectActionPermissions(appName: AppNamePermission, action: AppNameActionPermission) {
    return this.getValue()?.[appName]?.[action];
  }

  hasActionPermissions(appName: AppNamePermission, action: AppNameActionPermission) {
    return !!this.getValue()?.[appName]?.[action]?.length;
  }

  hasPermissionV2(appName: AppNamePermission, actions: AppNameActionPermission[]) {
    return actions.some(action => this.hasActionPermissions(appName, action));
  }
}

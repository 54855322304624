import { InfoMarkdown, markdownUtil, OutputProcessMessage } from '@b3networks/shared/common';

export enum NewsfeedStatus {
  active = 'active',
  archive = 'archive'
}

export class NewsfeedBase {
  channelId: string;
  channelName: string;
  status: NewsfeedStatus;
  org: OrganizationNewsFeed;
  description: string;
  at: number;
  role: RoleNewsFeed; // role is null , get from Discover, else this's my feed

  constructor(obj?: Partial<Newsfeed>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  get isMyFeed() {
    return !!this.role;
  }

  get isFromDiscover() {
    return !this.isMyFeed;
  }

  get isArchived() {
    return this.status === NewsfeedStatus.archive;
  }

  get displayRole() {
    if (this.role === RoleNewsFeed.admin) {
      return 'publisher';
    }
    return this.role;
  }
}

export class Newsfeed extends NewsfeedBase {
  // ui
  myOrg: string;
  aclAction: AclActionNewsfeed = <AclActionNewsfeed>{};
  buildIcon: BuildIconNewsfeed = <BuildIconNewsfeed>{};
  buildDescription: OutputProcessMessage;

  constructor(obj?: Partial<Newsfeed>) {
    super(obj);
    if (obj) {
      if (obj?.aclAction) this.aclAction = <AclActionNewsfeed>{ ...obj.aclAction };
      if (obj?.buildIcon) this.buildIcon = <BuildIconNewsfeed>{ ...obj.buildIcon };
      if (obj?.buildDescription) this.buildDescription = obj.buildDescription;

      this._initIcon();
      this._initUIAction();
      this._initBuildDescription();
    }
  }

  withOrgUuid(myOrg: string) {
    this.myOrg = myOrg;
    return this;
  }

  get isMyOrg() {
    return this.myOrg === this.org.uuid;
  }

  private _initBuildDescription() {
    if (this.description) {
      this.buildDescription = markdownUtil(this.description, <InfoMarkdown>{
        noCode: false,
        noSingleCode: false
      });
    }
  }

  private _initIcon() {
    this.buildIcon = {
      isSvg: false,
      classIcon: 'b3n-chip-text-green',
      icon: this.isArchived ? 'inventory_2' : 'newsmode'
    };
  }

  private _initUIAction() {
    // reset state
    this._resetAcl();

    if (!this.role) {
      this.aclAction.canSubscriber = !this.isArchived;
      return;
    }

    switch (this.role) {
      case RoleNewsFeed.owner:
        this.aclAction = {
          ...this.aclAction,
          canEditDescrition: !this.isArchived,
          canEditName: !this.isArchived,
          canManagePublisher: true,
          canManageSubscribers: true,
          canManageViewers: true,
          // canPost: !this.isArchived,
          canPost: true,
          canSchedulePost: !this.isArchived,
          canTransferOwnership: true,
          canArchive: !this.isArchived,
          canReopen: this.isArchived,
          canStar: !this.isArchived
        };
        break;
      case RoleNewsFeed.admin:
        this.aclAction = {
          ...this.aclAction,
          canLeave: !this.isArchived,
          canManagePublisher: true,
          canManageSubscribers: true,
          canManageViewers: true,
          canPost: true,
          canStar: !this.isArchived
        };
        break;
      case RoleNewsFeed.subscriber:
        this.aclAction = {
          ...this.aclAction,
          canUnSubscriber: !this.isArchived,
          canStar: !this.isArchived
        };
        break;
      default:
        break;
    }
  }

  private _resetAcl() {
    this.aclAction = <AclActionNewsfeed>{
      // action
      canSubscriber: false,
      canUnSubscriber: false,
      canLeave: false,
      canTransferOwnership: false,
      canArchive: false,
      canReopen: false,
      canStar: false,

      // channel
      canEditName: false,
      canEditDescrition: false,
      canManagePublisher: false,
      canManageViewers: false,
      canManageSubscribers: false,

      // post
      canPost: false,
      canSchedulePost: false
    };
  }
}

export interface BuildIconNewsfeed {
  icon: string;
  isSvg: boolean;
  classIcon: string;
}

export interface AclActionNewsfeed {
  // action
  canSubscriber: boolean;
  canUnSubscriber: boolean;
  canLeave: boolean;
  canTransferOwnership: boolean;
  canArchive: boolean;
  canReopen: boolean;
  canStar: boolean;

  // channel
  canEditName: boolean;
  canEditDescrition: boolean;
  canManagePublisher: boolean;
  canManageViewers: boolean;
  canManageSubscribers: boolean;

  // post
  canPost: boolean;
  canSchedulePost: boolean;
}

export interface OrganizationNewsFeed {
  uuid: string;
  name: string;
  logoUrl: string;
}

export enum RoleNewsFeed {
  owner = 'owner',
  admin = 'admin',
  // publisher = 'publisher',
  subscriber = 'subscriber'
}

export enum NFStatus {
  active = 'active',
  archived = 'archived'
}

export interface ReqCreateNewsFeed {
  name: string;
  description: string;
  adminIdentities: string[];
  publisherIdentities: string[];
  subscriberIdentities: string[];
}

export interface ReqUpdateNewsFeed {
  name: string;
  description: string;
}

export interface ReqSendMessageNF {
  chatMsgData: string; //json string of chat msg data
  mediaIds: string[]; //list of media id inclue in this post
}

export interface ReqUploadFileNF {
  name: string;
  size: number;
  fileType: string; // jpg
  medatadata: { [key: string]: string | number };
}

export interface ResUpdateFileNF {
  mediaId: string;
  token: string;
  filekey: string;
}

export interface MediaNewsFeed {
  mediaId: string;
  msgId: string;
  orgUuid: string;
  memberUuid: string;
  userId: string;
  filekey: string;
  name: string;
  size: number;
  fileType: string;
  medatadata: { [key: string]: string | number };
  createdAt: number;
}

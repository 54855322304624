import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformTitleAppName',
  standalone: true
})
export class TransformTitleAppNamePipe implements PipeTransform {
  private customTooltipNames = ['Simple SBC', 'SIP Trunk', 'BYOC Trunk', 'BYOP Trunk', 'Power SIP Trunk'];

  transform(appName: string): string {
    return this.customTooltipNames.includes(appName) ? appName : this.titleCase(appName);
  }

  private titleCase(str: string): string {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}

import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, map, take } from 'rxjs/operators';
import { LicenseFeatureCode } from '../constant';
import { Feature } from './feature.model';
import { FeatureStore } from './feature.store';

const EXTERNAL_ADDON_LICENSE_STARTWITH = 'externalapp_';

@Injectable({ providedIn: 'root' })
export class FeatureQuery extends Query<Feature> {
  selectAllFeatures$ = this.select('licenses');
  loaded$ = this.select('loaded').pipe(
    filter(loaded => loaded),
    take(1)
  );

  selectExternalAppIDs$ = this.select('licenses').pipe(
    map(list =>
      list
        ?.filter(l => l.startsWith(EXTERNAL_ADDON_LICENSE_STARTWITH))
        .map(l => l.substring(EXTERNAL_ADDON_LICENSE_STARTWITH.length))
    )
  );

  hasPhoneSystemBaseLicense$ = this.select('licenses').pipe(
    map(licenses => licenses?.some(x => x === LicenseFeatureCode.extension))
  );

  hasDeveloperLicense$ = this.select('licenses').pipe(
    map(licenses => licenses?.some(x => x === LicenseFeatureCode.developer))
  );

  hasContactCenterLicense$ = this.select('licenses').pipe(
    map(
      licenses =>
        licenses?.some(x => x === LicenseFeatureCode.license_center_supervisor) ||
        licenses?.some(x => x === LicenseFeatureCode.license_center_agent) ||
        licenses?.some(x => x === LicenseFeatureCode.license_call_center_enabled)
    )
  );

  hasSupportCenterLicense$ = this.select('licenses').pipe(
    map(licenses => licenses?.some(x => x === LicenseFeatureCode.license_support_center))
  );

  get hasPhoneSystemBaseLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.extension);
  }

  get hasFaxBaseLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_fax);
  }

  get hasCampaignLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_campaign);
  }

  get hasDeveloperLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.developer);
  }

  get hasCallCenterSupervisorLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_center_supervisor);
  }

  get hasCallCenterEnabledLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_call_center_enabled);
  }

  get hasCallCenterAgentLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_center_agent);
  }

  get hasSIPLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_sip);
  }

  get hasPowerSIPTrunkLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_power_sip_trunk);
  }

  get hasBYOCTrunkLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_byoc);
  }

  get hasBYOPTrunkLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_sip_byop);
  }

  get hasSimLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_base_sim);
  }

  get hasSimpleSBCLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_simple_sbc);
  }

  get hasContactCenterLicense() {
    return this.hasCallCenterSupervisorLicense || this.hasCallCenterAgentLicense || this.hasCallCenterEnabledLicense;
  }

  get hasLiveChatLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_livechat);
  }

  get hasSupportCenterLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_support_center);
  }

  get hasEmailLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_email);
  }

  get hasWhatsAppLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_whatsapp);
  }

  get hasAutoAttendantLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.auto_attendant);
  }

  get hasHotDeskingLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_hot_desking);
  }

  get hasFileExplorerBaseLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_file_explorer);
  }

  get hasFilesLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_files);
  }

  get hasOnboardLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.license_portal);
  }

  get hasTeamChatLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.team_chat);
  }

  get hasDashboardLicense() {
    return this.getValue()?.licenses?.some(x => x === LicenseFeatureCode.dashboard);
  }

  constructor(protected override store: FeatureStore) {
    super(store);
  }
}

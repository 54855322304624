import { randomGuid } from '../../plain_func.util';
import { OutputProcessMessage, WidgetCompile, WidgetMatched } from '../model/output-message.model';
import { RegExpPattern } from '../model/patterns.const';

// imgae ![text](url) -> ex: ![Logo](https://portal.hoiio.net/...)
export class ImageMarkdownCompiler implements WidgetCompile {
  constructor() {}

  preCompile(data: OutputProcessMessage): WidgetMatched[] {
    const imageMatched = data.text.match(RegExpPattern.IMAGE);
    const matchs: WidgetMatched[] = [];
    if (imageMatched) {
      imageMatched.forEach(item => {
        const replaceString: string = randomGuid();
        data.text = data.text.replace(item, replaceString);
        matchs.push({ random: replaceString, text: item });
      });
    }
    return matchs;
  }

  compile(matches: WidgetMatched[], data: OutputProcessMessage) {
    matches.forEach(item => {
      let replaceItem = item.text;
      const parts = item.text.match(/!\[(.*?)\]\(([^"\s]+(?:\([^"\s]*\))?[^"\s]*)\s*(?:"(.*?)")?\)/);
      if (parts) {
        const text = parts[1];
        const src = parts[2];
        replaceItem = `<b3n-image-message-renderer data-src="${src}" data-alt="${text}"> </b3n-image-message-renderer>`;
      }
      data.isTriggerDirective = true;
      data.text = data.text.replace(item.random, replaceItem);
      data.hasImageMarkdown = true;
    });
  }
}

export class Match {
  constructor(
    public type: number,
    public matched: string,
    public replaceString: string
  ) {}
}

export enum MatchType {
  URL,
  EMAIL,
  MENTION,
  CHANNEL,
  EMOJI,
  // deprecated
  BLOCKQUOTE,
  CODE,
  BOLD,
  ITALIC,
  // other
  LINK,
  HIGHLIGHT_LINK,
  NEW_LINE,
  STRIKE,
  IMAGE
}

export enum ChatType {
  channel = 'channel',
  email = 'email',
  whatsapp = 'whatsapp',
  livechat = 'livechat',
  sms = 'sms'
}

export let PREFIX_LINK = '';
export function changePrefixLink(link) {
  PREFIX_LINK = link;
}

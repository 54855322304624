import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ConnectItem } from '@b3networks/api/connect';
import { OrgConnection } from '@b3networks/api/auth';
import { SipAccount } from '@b3networks/api/callcenter';
import { SupplierSeller } from '@b3networks/api/supplier';

export interface SimpleSBCState extends EntityState<SipAccount>, ActiveState {
  availableSips: SipAccount[];
  assignedSips: SipAccount[];
  listSip: SipAccount[];
  listApp: ConnectItem[];
  listOrgConnector: OrgConnection[];
  listSuppliers: SupplierSeller[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'callcenter_simplesbc', idKey: 'sipUsername' })
export class SimpleSBCStore extends EntityStore<SimpleSBCState> {
  constructor() {
    super({
      availableSips: [],
      assignedSips: [],
      listSip: [],
      listApp: [],
      listOrgConnector: [],
      listSuppliers: []
    });
  }
}

import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { SharedUiMaterialModule } from '@b3networks/shared/ui/material';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'shc-color-picker',
  templateUrl: './color-picker.component.html',
  imports: [SharedUiMaterialModule, CommonModule],
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements AfterViewInit {
  @Input() selectedColor: string;
  @Input() colors: string[] = [];
  @Output() colorChange = new EventEmitter<string>();

  private readonly DEFAULT_COLORS: Record<string, string> = {
    '--b3n-preset-color-default': '#1f2123',
    '--b3n-preset-color-blue': ' #0a5d9f',
    '--b3n-preset-color-green': ' #0c7b52',
    '--b3n-preset-color-yellow': ' #816000',
    '--b3n-preset-color-red': ' #DB1000',
    '--b3n-preset-color-purple': ' #6d1dd2',
    '--b3n-preset-color-gray': ' #1f212399',
    '--b3n-preset-color-light-blue': ' #138fce',
    '--b3n-preset-color-light-green': '#36b37e',
    '--b3n-preset-color-light-yellow': '#e0a025',
    '--b3n-preset-color-light-red': '#f55448',
    '--b3n-preset-color-light-purple': ' #caadee'
  };

  get colorList(): string[] {
    return this.colors.length > 0
      ? this.colors
      : Object.entries(this.DEFAULT_COLORS).map(([key, value]) => `var(${key}, ${value.trim()})`);
  }

  selectColor(color: string) {
    this.selectedColor = color;
    this.colorChange.emit(color);
  }

  ngAfterViewInit(): void {
    if (!this.selectedColor) {
      const firstEntry = Object.entries(this.DEFAULT_COLORS)[0];
      if (firstEntry) {
        const [key, value] = firstEntry;
        this.selectedColor = `var(${key}, ${value.trim()})`;
      }
    }
  }
}
